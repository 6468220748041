import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let TableDropDownTag = class TableDropDownTag extends Vue {
    /**
     * 获取指定个数的标签数
     */
    gerenatellipsisTags(value = [], ellipsis) {
        if (ellipsis) {
            return value?.slice(0, 2);
        }
        return value;
    }
    get getElipsisTags() {
        return this.gerenatellipsisTags(this.list, true);
    }
    get getAllTags() {
        return this.gerenatellipsisTags(this.list);
    }
};
__decorate([
    Prop()
], TableDropDownTag.prototype, "list", void 0);
TableDropDownTag = __decorate([
    Component({
        name: 'TableDropDownTag',
    })
], TableDropDownTag);
export default TableDropDownTag;
